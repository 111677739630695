
import {ChatAiWidget} from "@sendbird/chat-ai-widget";
import "@sendbird/chat-ai-widget/dist/style.css";

function App() {
  return (
    <div>
      This is Developed by Gihan
      <ChatAiWidget
          applicationId="B9473985-D7F0-4EE0-93A5-77DD9A44D44A"
          botId="AgenciadeViajes1"
      />
    </div>
  );
}

export default App;
